const nearbyAmenities = {
    calculateNearby: async (lat, lng, siteId = null) => {
        if (!lat || !lng || !siteId) return;

        const nearbyCategoryElements = document.querySelectorAll(`[data-nearby-category]`);
        const nearbyAmenityElements = document.querySelectorAll(`[data-nearby-amenity]`);

        // only proceed if there are any elements to render the amenities into
        if (nearbyCategoryElements.length === 0 && nearbyAmenityElements.length === 0) return;

        // Go fetch the amenities from '/actions/_simple/lanser/nearby-amenities' and then render them
        fetch(`/actions/_simple/lanser/nearby-amenities?lat=${lat}&lng=${lng}&siteId=${siteId}`)
            .then(response => response.json())
            .then(data => {
                // assign the amenities to the elements where the slug matches the element's data-nearby-category attribute
                nearbyAmenities.applyAmenities(data);
            });
    },
    applyAmenities: (amenities) => {
        // This applies the distances to any amenity category element
        const nearbyCategoryElements = document.querySelectorAll(`[data-nearby-category]`);
        nearbyCategoryElements.forEach(element => {
            const key = element.dataset.nearbyCategory;
            if (key && amenities[key]) {
                const amenity = amenities[key].amenity;
                const distance = Number(amenities[key].distance).toFixed(1);
                element.innerHTML = `${distance}km`;
            } else {
                console.warn(`No amenities found for ${key}`);
            }
        });

        // this applies the distance to specific amenities (i.e. shows the title and distance)
        const nearbyAmenityElements = document.querySelectorAll(`[data-nearby-amenity]`);
        nearbyAmenityElements.forEach(element => {
            const key = element.dataset.nearbyAmenity;
            if (key && amenities[key]) {
                const amenity = amenities[key].amenity;
                const distance = Number(amenities[key].distance).toFixed(1);
                element.innerHTML = `${distance}km from ${amenity.title}`;
            }
        });
    }
}

export default nearbyAmenities;
