import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import initGoogleLayer from './leaflet-google-plugin';
const initMaps = () => {
    // look for a document ID of #leafletMap
    const theMapWrapper = document.getElementById('leafletMapWrapper')
    const theMapEl = document.getElementById('leafletMap');

    const mapLayerControls = document.querySelectorAll('[data-toggle-layer]')

    // helper function to calculate the pixel distance between two points
    const calculateDistance = function(x1, y1, x2, y2) {
        const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        return distance;
    }

    // Function to toggle layer groups within a map
    const toggleLayerGroup = function(map, layerGroup) {
        if (typeof layerGroup === 'undefined') {
            return false;
        }

        if (map.hasLayer(layerGroup)) {
            map.removeLayer(layerGroup);
        } else {
            map.addLayer(layerGroup);
        }
        return true;
    }

    if (theMapEl) {
        // define the map centre point based on user-given data attributes
        const mapLat = theMapEl.getAttribute('data-map-center-lat');
        const mapLng = theMapEl.getAttribute('data-map-center-lng');
        const mapZoom = theMapEl.getAttribute('data-map-zoom') ?? 15;
        const mapType = 'google'; // 'google' or 'osm'
        // define the map layer bounds
        const displayLotShapes = window.lanserCommunity.displayLotShapes ?? false;
        const baseMapLayerBounds = window.lanserCommunity.base.baseMapLayerBounds ?? [];
        const baseMapScaleFactor = window.lanserCommunity.base.imgScaleFactor ?? 1;
        const baseMapImageSrc = window.lanserCommunity.base.imgSrc ?? '';
        const baseMapWidth = Number(window.lanserCommunity.base.imgWidth) ?? 1;
        const baseMapHeight = Number(window.lanserCommunity.base.imgHeight) ?? 1;
        const baseMapOpacity = window.lanserCommunity.base.imgOpacity ?? 1;
        const amenities = window.lanserCommunity.amenities ?? [];

        if (baseMapLayerBounds.length < 1) {
            console.error('Whoops! No map layer bounds provided in CMS!')
            return;
        }

        // Determine all unique amenity categories from the amenities array, 
        // so we can create a layerGroup for each category
        const allAmenityCategories = Array.from(
            new Set(amenities.map(amenity => JSON.stringify({ title: amenity.category, slug: amenity.categorySlug })))
        ).map(JSON.parse);

        // define all of the final control layergroup objects in a handy object for later
        let mapLayerGroups = {
            radiuses: L.layerGroup(),
            releases: L.layerGroup(),
            stages: L.layerGroup(),
            precincts: L.layerGroup(),
        }

        // ...and add a layerGroup for each amenity category
        allAmenityCategories.forEach(cat => {
            const catSlug = 'amenities__' + cat.slug
            mapLayerGroups[catSlug] = L.layerGroup()
        })

        // Also define a function to handle the layer control buttons via external DOM elements
        mapLayerControls.forEach(el => {
            el.addEventListener('click', function() {
                console.log('toggleLayerGroup', el.dataset.toggleLayer, mapLayerGroups)
                const result = toggleLayerGroup(masterplanMap, mapLayerGroups[el.dataset.toggleLayer])
                if (!result) {
                    console.error(`Layer group ${el.dataset.toggleLayer} not found in layerGroups (${Object.keys(mapLayerGroups).join(', ')})`)
                }
            })
        })

        // ====================

        // define a new map tile layer
        const tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20,
        })

        // TODO: get google maps working as a layer
        switch (mapType) {
            case 'google':
                // Initialize Google Layer
                initGoogleLayer()

                break;
            case 'osm':
                // tings
                break;
            default:
                // tings
                break;
        }

        // ----- MAP LAYER ----- //
        // create a new map object at the window level
        // NOTE: we hard-code the initial map creation zoom level, as it affects the imgBounds calc below.. 
        // then once we have added the layers, we re-set the zoom level as defined in the CMS-specified zoom attribute
        window.masterplanMap = L.map('leafletMap',{
            scrollWheelZoom: false,
            center: [mapLat, mapLng],
            zoom: 18,
            layers: [tileLayer, mapLayerGroups.radiuses, mapLayerGroups.stages, mapLayerGroups.precincts],
        })

        // ----- BASE MAP IMAGE ----- //
        // If there are two latLngBounds provided in baseMapLayerBounds, we use both to calculate the bounding box of 
        // the map image, otherwise we use the first latLngBounds, the map width/height and the imgScaleFactor to calculate the bounding box of the map image
        let imgBounds = [];
        if (baseMapLayerBounds.length === 2) {
            // use both latLngBounds to calculate the bounding box of the map image
            imgBounds = L.latLngBounds([
                baseMapLayerBounds[0],
                baseMapLayerBounds[1],
            ])
        } else {
            // use the first latLngBounds anc calc the rest. We calculate the resulting lat/lng of the bottom corner using 
            // various leaflet methods to get the map container points and then convert back to latLng

            // the containerPoint of the top left and bottom right corners of the map image
            const originContainerPoint = masterplanMap.latLngToContainerPoint(baseMapLayerBounds[0], theMapEl)
            const destinationContainerPoint = L.point(
                originContainerPoint.x + (baseMapWidth * baseMapScaleFactor),
                originContainerPoint.y + (baseMapHeight * baseMapScaleFactor)
            )

            // the latLng of the bottom right corner of the map image
            const destinationLatLngCoords = masterplanMap.containerPointToLatLng(destinationContainerPoint)

            imgBounds = L.latLngBounds([
                baseMapLayerBounds[0],
                destinationLatLngCoords
            ])
        }

        // ----- RADIUSES & DISTANCE MARKERS----- //
        // 3 radius circles (3km, 5km, 10km) emanating from the centre of the map
        const radius3km = L.circle([mapLat, mapLng], { radius: 3000, color: '#C06C39', fill: false, opacity: 0.5 })
        const radius5km = L.circle([mapLat, mapLng], { radius: 5000, color: '#C06C39', fill: false, opacity: 0.5 })
        const radius10km = L.circle([mapLat, mapLng], { radius: 10000, color: '#C06C39', fill: false, opacity: 0.5 })

        radius3km.addTo(mapLayerGroups.radiuses)
        radius5km.addTo(mapLayerGroups.radiuses)
        radius10km.addTo(mapLayerGroups.radiuses)

        // 6 markers, one for each radius circle, at the south and north points of the circle
        const latLng3kmNorth = moveLatLng(mapLat, mapLng, 3000, 'north');
        const latLng5kmNorth = moveLatLng(mapLat, mapLng, 5000, 'north');
        const latLng10kmNorth = moveLatLng(mapLat, mapLng, 10000, 'north');
        const latLng3kmSouth = moveLatLng(mapLat, mapLng, 3000, 'south');
        const latLng5kmSouth = moveLatLng(mapLat, mapLng, 5000, 'south');
        const latLng10kmSouth = moveLatLng(mapLat, mapLng, 10000, 'south');

        const radius3kmMarkerNorth = L.marker([latLng3kmNorth.lat, latLng3kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-3km">3km</span>'}) })
        const radius3kmMarkerSouth = L.marker([latLng3kmSouth.lat, latLng3kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-3km">3km</span>'}) })
        const radius5kmMarkerNorth = L.marker([latLng5kmNorth.lat, latLng5kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-5km">5km</span>'}) })
        const radius5kmMarkerSouth = L.marker([latLng5kmSouth.lat, latLng5kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-5km">5km</span>'}) })
        const radius10kmMarkerNorth = L.marker([latLng10kmNorth.lat, latLng10kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-10km">10km</span>'}) })
        const radius10kmMarkerSouth = L.marker([latLng10kmSouth.lat, latLng10kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-10km">10km</span>'}) })

        radius3kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius3kmMarkerNorth.addTo(mapLayerGroups.radiuses)
        radius5kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius5kmMarkerNorth.addTo(mapLayerGroups.radiuses)
        radius10kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius10kmMarkerNorth.addTo(mapLayerGroups.radiuses)

        // ----- STAGES ----- //
        // Loop through window.lanserCommunity.stages and add their image to the map layers
        // NOTE: this is currently unused as we are using precincts instead, as per client request (14 Oct)
        for(let i = 0; i < window.lanserCommunity.stages.length; i++) {
            
            // set the image url
            // const imageUrl = window.lanserCommunity.stages[i].imageUrl
            // define the image/svg overlay for the specific stage
            // const stage = L.imageOverlay(imageUrl, imgBounds, {
            //     opacity: 1,
            //     zIndex: 1 + i,
            // })

            // or... set an svgElement by retrieving the image from the DOM
            const svgElement = document.querySelector(`svg.stagemap_${i}`)
            const stage = L.svgOverlay(svgElement, imgBounds, {
                opacity: 1,
                zIndex: 1 + i,
            })

            // add a new stage layerGroup to the mapLayerGroups 'stages' object
            stage.addTo(mapLayerGroups.stages)
        }

        // ----- PRECINCTS ----- //
        // Loop through window.lanserCommunity.precincts and add their image to the map layers
        for(let i = 0; i < window.lanserCommunity.precincts.length; i++) {
            
            // set the image url
            // const imageUrl = window.lanserCommunity.precincts[i].imageUrl
            // define the image/svg overlay for the specific precinct
            // const precinct = L.imageOverlay(imageUrl, imgBounds, {
            //     opacity: 1,
            //     zIndex: 1 + i,
            // })

            // or... set an svgElement by retrieving the image from the DOM
            const svgElement = document.querySelector(`svg.precinctmap_${i}`)
            const precinct = L.svgOverlay(svgElement, imgBounds, {
                opacity: 1,
                zIndex: 1 + i,
            })

            // add a new precinct layerGroup to the mapLayerGroups 'precinct' object
            precinct.addTo(mapLayerGroups.precincts)
        }

        // ----- RELEASES ----- //
        // Loop through window.lanserCommunity.releases and add their image to the map layers
        if (displayLotShapes) {
            for(let i = 0; i < window.lanserCommunity.releases.length; i++) {
                
                // set the image url
                // const imageUrl = window.lanserCommunity.releases[i].imageUrl
                // define the image/svg overlay for the specific release
                // const release = L.imageOverlay(imageUrl, imgBounds, {
                //     opacity: 1,
                //     zIndex: 1 + i,
                // })

                // or... set an svgElement by retrieving the image from the DOM
                const svgElement = document.querySelector(`svg.releasemap_${i}`)
                if (!svgElement) {
                    console.warn(`No valid SVG element found for release '${window.lanserCommunity.releases[i].slug}'`)
                    continue
                }
                const release = L.svgOverlay(svgElement, imgBounds, {
                    opacity: 1,
                    zIndex: 1 + i,
                })

                // add a new release layerGroup to the mapLayerGroups 'releases' object
                release.addTo(mapLayerGroups.releases)
            }
            // enable releases layerGroup on the map by default
            window.masterplanMap.addLayer(mapLayerGroups.releases)
        }

        // ----- AMENITIES ----- //
        // Loop through the amenities and create a marker for each
        for(let i = 0; i < amenities.length; i++) {
            const amenity = amenities[i]

            const amenityMarker = L.marker([amenity.lat, amenity.lng], {
                icon: L.divIcon({
                    className: `amenity-icon ${amenity.icon}`,
                    html: `<div class="pin"><svg><use href="#amenity-${amenity.icon}"></use></svg><span class="icon-bg"></span></div>`,
                    iconSize: [56, 56],
                    iconAnchor: [23, 56],
                }),
            })
                // .bindPopup(amenity.title)
                .on('click', function() {
                    masterplanControls.showAmenityDetails(amenity.slug)
                })

            // add a new amenity layerGroup to the appropriate namespaced mapLayerGroups 'amenities__{categorySlug}' object
            const catName = 'amenities__' + amenity.categorySlug
            amenityMarker.addTo(mapLayerGroups[catName])
        };

        // ----- LOTS (circles) ----- //
        // (optional/fallback) lat/lng clickable circles for each lot (in case SVG outlines are not available)
        if (!displayLotShapes) {
            for (let i = 0; i < lanserCommunity.lots.length; i++) {
                const lot = lanserCommunity.lots[i]

                let fillColor
                switch (lot.status.toLowerCase()) {
                    case 'available':
                        fillColor = '#00DC00'
                        break;
                    case 'on hold':
                        fillColor = '#FFD700'
                        break;
                    case 'deposit taken':
                        fillColor = '#FFD700'
                        break;
                    case 'sold':
                        fillColor = '#FF0000'
                        break;
                    default:
                        fillColor = '#FF6900'
                        break;
                }

                L.circle([lot.lat, lot.lng], {radius: 5, fillColor: fillColor, color: fillColor })
                    .addTo(masterplanMap)
                    // .bindPopup(`Lot ${lot.lotNo}`)
                    .on('click', function() {
                        masterplanControls.showLotDetails(lot.id)
                    })
            }
        }


        // ----- BASE MAP IMAGE ----- //
        // create the community base layer image, add it to the map & 
        // re-set the zoom level as defined in the data attribute
        const communityBaseLayer = L.imageOverlay(baseMapImageSrc, imgBounds, {
            opacity: baseMapOpacity,
            zIndex: 1,
        })
        communityBaseLayer.addTo(window.masterplanMap)
        window.masterplanMap.setZoom(mapZoom)

        // ----- LAYERS CONTROL ----- //
        // finally create a new Layers Control and add it to the map, 
        // with the mapLayerGroup stages / precincts as the default 'on'
        const layersControl = L.control.layers(null, {
            "Show Stages": mapLayerGroups.stages,
            "Show Precincts": mapLayerGroups.precincts,
        }, {
            collapsed: true,
        }).addTo(masterplanMap)

        // if displayLotShapes is true, add the 'releases' layerGroup to the layersControl
        if (displayLotShapes) {
            layersControl.addOverlay(mapLayerGroups.releases, 'Show Releases')
        }

        // afterwards, add each of the namespaced 'amenities__{categorySlug}' layerGroups to the layersControl
        allAmenityCategories.forEach(cat => {
            const catSlug = 'amenities__' + cat.slug
            // layersControl.addOverlay(mapLayerGroups[catSlug], cat.title) // adds to the layersControl pane (in default OFF state)
            window.masterplanMap.addLayer(mapLayerGroups[catSlug], cat.title) // adds to the map itself (in default ON state)
        })

        // ----- RESIZE OBSERVER ----- //
        // Resize observer to handle map resizing
        const resizeObserver = new ResizeObserver(() => {
            // console.log('resizing')
            masterplanMap.invalidateSize()
        });
        
        if (theMapWrapper) {
            resizeObserver.observe(theMapWrapper)
            masterplanMap.invalidateSize() // initial resize
        }

        // init the lot shapes click-handler
        initLotShapesHandler()

        // TESTING: lat/lng demolots from Christie
        // demoLots.forEach(lot => {
        //     L.circle([lot.lat, lot.lng])
        //         .addTo(masterplanMap)
        //         .bindPopup(`Lot ${lot.lotNo}`)
        // });
    }
}

// lot shape onClick handler
const initLotShapesHandler = function() {
    const theMapWrapper = document.getElementById('leafletMapWrapper')
    const releaseMaps = []
    if (theMapWrapper) {
        const releaseMapEls = theMapWrapper.querySelectorAll('svg[data-release]')
        releaseMapEls.forEach(el => {
            // only select the first-level children elements of each releaseMapEl, 
            // where each child is a path, polygon, rect or g element
            const lotShapeEls = el.querySelectorAll('path, polygon, rect, g')

            if (lotShapeEls.length > 0) {
                const lotShapes = []
                lotShapeEls.forEach(shape => {
                    // calculate the lotId from the shape's ID i.e. "LOT_2756_Pine_Valley_R2"
                    const lotData = lotNameDeCombobulator(shape.id)
                    lotShapes.push({
                        el: shape,
                        data: lotData,
                    })
                })
                // add a click handler to each shape
                lotShapes.forEach(shape => {
                    shape.el.addEventListener('click', function() {
                        masterplanControls.showLotDetails(shape.data)
                    })
                })
                // push it all up to the releaseMaps array
                releaseMaps.push({
                    id: el.dataset.releaseId,
                    slug: el.dataset.releaseSlug,
                    lotShapes: lotShapes,
                })
            }
        })
    }
}

const lotNameDeCombobulator = function(lotId) {
    // LOT_2756_Pine-Valley_R2 -> { lotNo: 2756, name: Pine-Valley, release: R2 }
    const parts = lotId.split('_')
    if (parts.length !== 4) {
        console.warn(`Invalid lotId format: ${lotId} - expected 4 parts, got ${parts.length}`)
    }
    return {
        lotNo: parts[1] ?? null,
        releaseName: parts[2] ?? null,
        releaseId: parts[3] ?? null,
    }
}

// misc functions to control the map from outside the module
const masterplanControls = {
    zoomToArea: function(el) {
        const opts = el.dataset
        console.log(`panning to ${opts.lat}, ${opts.lng} at zoom ${opts.zoom}`)
        window.masterplanMap.setView([opts.lat, opts.lng], opts.zoom, { animate: true })
    },
    showLotDetails: function(lotData) {
        // determine the lot to show based on the lotData supplied
        const selectedLot = window.lanserCommunity.lots.find(lot => {
            return (lot.lotNo == lotData.lotNo) && (lot.releaseSlug.toLowerCase().includes(lotData.releaseName.toLowerCase()))
        })
        if (selectedLot) {
            // dispatch custom window event 'showlotdetails' with the lotId as the detail
            window.dispatchEvent(new CustomEvent('showlotdetails', { detail: selectedLot.id }))
        } else {
            console.warn(`No matching lot number ${lotData.lotNo} found in release '${lotData.releaseName}'`)
        }
    },
    showAmenityDetails: function(amenitySlug) {
        console.log('showAmenityDetails', amenitySlug)
        // dispatch custom window event 'showamenitydetails' with the lotId as the detail
        window.dispatchEvent(new CustomEvent('showamenitydetails', { detail: amenitySlug }))
    }
}

const moveLatLng = function(lat, lng, distance, direction) {
    const earthRadius = 6371000; // meters
    const rad = Math.PI / 180;   // Convert degrees to radians

    // Convert latitude and longitude to numbers (in case they are strings)
    lat = parseFloat(lat);
    lng = parseFloat(lng);
    distance = parseFloat(distance);

    // Adjust latitude
    if (direction === 'north' || direction === 'south') {
        const newLat = lat + (distance / 111320) * (direction === 'north' ? 1 : -1);
        return { lat: newLat, lng: lng };
    }

    // Adjust longitude
    else if (direction === 'east' || direction === 'west') {
        const newLng = lng + (distance / (111320 * Math.cos(lat * rad))) * (direction === 'east' ? 1 : -1);
        return { lat: lat, lng: newLng };
    }

    // If direction is invalid
    else {
        console.error('Invalid direction specified. Use "north", "south", "east", or "west".');
        return null;
    }
}


export default { initMaps, masterplanControls }
